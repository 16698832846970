// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-2023-js": () => import("./../../../src/pages/about-us-2023.js" /* webpackChunkName: "component---src-pages-about-us-2023-js" */),
  "component---src-pages-about-us-secondary-js": () => import("./../../../src/pages/about-us-secondary.js" /* webpackChunkName: "component---src-pages-about-us-secondary-js" */),
  "component---src-pages-advertise-with-us-js": () => import("./../../../src/pages/advertise-with-us.js" /* webpackChunkName: "component---src-pages-advertise-with-us-js" */),
  "component---src-pages-aire-benchmarking-report-india-js": () => import("./../../../src/pages/aire-benchmarking-report-india.js" /* webpackChunkName: "component---src-pages-aire-benchmarking-report-india-js" */),
  "component---src-pages-aire-benchmarking-report-js": () => import("./../../../src/pages/aire-benchmarking-report.js" /* webpackChunkName: "component---src-pages-aire-benchmarking-report-js" */),
  "component---src-pages-aire-benchmarking-report-ksa-js": () => import("./../../../src/pages/aire-benchmarking-report-KSA.js" /* webpackChunkName: "component---src-pages-aire-benchmarking-report-ksa-js" */),
  "component---src-pages-aire-benchmarking-report-uae-js": () => import("./../../../src/pages/aire-benchmarking-report-UAE.js" /* webpackChunkName: "component---src-pages-aire-benchmarking-report-uae-js" */),
  "component---src-pages-aire-benchmarking-report-uk-js": () => import("./../../../src/pages/aire-benchmarking-report-uk.js" /* webpackChunkName: "component---src-pages-aire-benchmarking-report-uk-js" */),
  "component---src-pages-aire-benchmarking-report-united-states-js": () => import("./../../../src/pages/aire-benchmarking-report-united-states.js" /* webpackChunkName: "component---src-pages-aire-benchmarking-report-united-states-js" */),
  "component---src-pages-aire-framework-employee-recognition-and-rewards-js": () => import("./../../../src/pages/aire-framework-employee-recognition-and-rewards.js" /* webpackChunkName: "component---src-pages-aire-framework-employee-recognition-and-rewards-js" */),
  "component---src-pages-aire-framework-whitepaper-for-conference-attendees-js": () => import("./../../../src/pages/aire-framework-whitepaper-for-conference-attendees.js" /* webpackChunkName: "component---src-pages-aire-framework-whitepaper-for-conference-attendees-js" */),
  "component---src-pages-amazon-business-integration-old-js": () => import("./../../../src/pages/amazon-business-integration-OLD.js" /* webpackChunkName: "component---src-pages-amazon-business-integration-old-js" */),
  "component---src-pages-amazon-business-integrations-js": () => import("./../../../src/pages/amazon-business-integrations.js" /* webpackChunkName: "component---src-pages-amazon-business-integrations-js" */),
  "component---src-pages-annual-rewards-and-recognition-benchmarking-report-india-2024-js": () => import("./../../../src/pages/annual-rewards-and-recognition-benchmarking-report-india-2024.js" /* webpackChunkName: "component---src-pages-annual-rewards-and-recognition-benchmarking-report-india-2024-js" */),
  "component---src-pages-annual-rewards-and-recognition-benchmarking-report-uae-2024-js": () => import("./../../../src/pages/annual-rewards-and-recognition-benchmarking-report-UAE-2024.js" /* webpackChunkName: "component---src-pages-annual-rewards-and-recognition-benchmarking-report-uae-2024-js" */),
  "component---src-pages-book-a-demo-capterra-js": () => import("./../../../src/pages/book-a-demo-capterra.js" /* webpackChunkName: "component---src-pages-book-a-demo-capterra-js" */),
  "component---src-pages-book-a-demo-g-2-js": () => import("./../../../src/pages/book-a-demo-g2.js" /* webpackChunkName: "component---src-pages-book-a-demo-g-2-js" */),
  "component---src-pages-book-a-demo-sourceforge-js": () => import("./../../../src/pages/book-a-demo-sourceforge.js" /* webpackChunkName: "component---src-pages-book-a-demo-sourceforge-js" */),
  "component---src-pages-book-a-demo-ssr-js": () => import("./../../../src/pages/book-a-demo-ssr.js" /* webpackChunkName: "component---src-pages-book-a-demo-ssr-js" */),
  "component---src-pages-book-a-meeting-v-1-js": () => import("./../../../src/pages/book-a-meeting-v1.js" /* webpackChunkName: "component---src-pages-book-a-meeting-v-1-js" */),
  "component---src-pages-calculators-employee-net-promoter-score-enps-calculator-index-js": () => import("./../../../src/pages/calculators/employee-net-promoter-score-enps-calculator/index.js" /* webpackChunkName: "component---src-pages-calculators-employee-net-promoter-score-enps-calculator-index-js" */),
  "component---src-pages-calculators-index-js": () => import("./../../../src/pages/calculators/index.js" /* webpackChunkName: "component---src-pages-calculators-index-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-careers-old-js": () => import("./../../../src/pages/careers-old.js" /* webpackChunkName: "component---src-pages-careers-old-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-competitors-js": () => import("./../../../src/pages/competitors.js" /* webpackChunkName: "component---src-pages-competitors-js" */),
  "component---src-pages-corporate-gifting-solutions-js": () => import("./../../../src/pages/corporate-gifting-solutions.js" /* webpackChunkName: "component---src-pages-corporate-gifting-solutions-js" */),
  "component---src-pages-dubai-hr-think-tank-js": () => import("./../../../src/pages/dubai-hr-think-tank.js" /* webpackChunkName: "component---src-pages-dubai-hr-think-tank-js" */),
  "component---src-pages-employee-engagement-survey-platform-v-1-js": () => import("./../../../src/pages/employee-engagement-survey-platform-v1.js" /* webpackChunkName: "component---src-pages-employee-engagement-survey-platform-v-1-js" */),
  "component---src-pages-employee-recognition-program-v-2-js": () => import("./../../../src/pages/employee-recognition-program-v2.js" /* webpackChunkName: "component---src-pages-employee-recognition-program-v-2-js" */),
  "component---src-pages-employee-recognition-program-v-3-js": () => import("./../../../src/pages/employee-recognition-program-v3.js" /* webpackChunkName: "component---src-pages-employee-recognition-program-v-3-js" */),
  "component---src-pages-employeespeaks-js": () => import("./../../../src/pages/employeespeaks.js" /* webpackChunkName: "component---src-pages-employeespeaks-js" */),
  "component---src-pages-events-shrmgcc-js": () => import("./../../../src/pages/events/shrmgcc.js" /* webpackChunkName: "component---src-pages-events-shrmgcc-js" */),
  "component---src-pages-events-shrmtech-24-hyderabad-js": () => import("./../../../src/pages/events/shrmtech24/hyderabad.js" /* webpackChunkName: "component---src-pages-events-shrmtech-24-hyderabad-js" */),
  "component---src-pages-free-ebook-rewards-recognition-2-js": () => import("./../../../src/pages/free-ebook-rewards-recognition-2.js" /* webpackChunkName: "component---src-pages-free-ebook-rewards-recognition-2-js" */),
  "component---src-pages-global-rewards-catalog-js": () => import("./../../../src/pages/global-rewards-catalog.js" /* webpackChunkName: "component---src-pages-global-rewards-catalog-js" */),
  "component---src-pages-glossaries-js": () => import("./../../../src/pages/glossaries.js" /* webpackChunkName: "component---src-pages-glossaries-js" */),
  "component---src-pages-home-2023-js": () => import("./../../../src/pages/home-2023.js" /* webpackChunkName: "component---src-pages-home-2023-js" */),
  "component---src-pages-home-v-6-js": () => import("./../../../src/pages/homeV6.js" /* webpackChunkName: "component---src-pages-home-v-6-js" */),
  "component---src-pages-hr-academy-industry-reports-js": () => import("./../../../src/pages/hr-academy/industry-reports.js" /* webpackChunkName: "component---src-pages-hr-academy-industry-reports-js" */),
  "component---src-pages-hr-academy-whitepapers-js": () => import("./../../../src/pages/hr-academy/whitepapers.js" /* webpackChunkName: "component---src-pages-hr-academy-whitepapers-js" */),
  "component---src-pages-in-the-press-js": () => import("./../../../src/pages/in-the-press.js" /* webpackChunkName: "component---src-pages-in-the-press-js" */),
  "component---src-pages-integration-js": () => import("./../../../src/pages/integration.js" /* webpackChunkName: "component---src-pages-integration-js" */),
  "component---src-pages-integration-microsoft-teams-2-js": () => import("./../../../src/pages/integration/microsoft-teams-2.js" /* webpackChunkName: "component---src-pages-integration-microsoft-teams-2-js" */),
  "component---src-pages-joining-kit-js": () => import("./../../../src/pages/joining-kit.js" /* webpackChunkName: "component---src-pages-joining-kit-js" */),
  "component---src-pages-newfonthome-js": () => import("./../../../src/pages/newfonthome.js" /* webpackChunkName: "component---src-pages-newfonthome-js" */),
  "component---src-pages-newperkspage-js": () => import("./../../../src/pages/newperkspage.js" /* webpackChunkName: "component---src-pages-newperkspage-js" */),
  "component---src-pages-newpulsepage-js": () => import("./../../../src/pages/newpulsepage.js" /* webpackChunkName: "component---src-pages-newpulsepage-js" */),
  "component---src-pages-newrewardspage-js": () => import("./../../../src/pages/newrewardspage.js" /* webpackChunkName: "component---src-pages-newrewardspage-js" */),
  "component---src-pages-partnership-announcements-js": () => import("./../../../src/pages/partnership-announcements.js" /* webpackChunkName: "component---src-pages-partnership-announcements-js" */),
  "component---src-pages-plans-and-pricing-js": () => import("./../../../src/pages/plans-and-pricing.js" /* webpackChunkName: "component---src-pages-plans-and-pricing-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-pointwebcastlive-js": () => import("./../../../src/pages/pointwebcastlive.js" /* webpackChunkName: "component---src-pages-pointwebcastlive-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-product-updates-js": () => import("./../../../src/pages/product-updates.js" /* webpackChunkName: "component---src-pages-product-updates-js" */),
  "component---src-pages-products-employee-discount-js": () => import("./../../../src/pages/products/employee-discount.js" /* webpackChunkName: "component---src-pages-products-employee-discount-js" */),
  "component---src-pages-products-employee-surveys-js": () => import("./../../../src/pages/products/employee-surveys.js" /* webpackChunkName: "component---src-pages-products-employee-surveys-js" */),
  "component---src-pages-products-rewards-recognition-js": () => import("./../../../src/pages/products/rewards-recognition.js" /* webpackChunkName: "component---src-pages-products-rewards-recognition-js" */),
  "component---src-pages-pulse-registration-js": () => import("./../../../src/pages/pulse-registration.js" /* webpackChunkName: "component---src-pages-pulse-registration-js" */),
  "component---src-pages-recognition-and-rewards-reports-js": () => import("./../../../src/pages/recognition-and-rewards-reports.js" /* webpackChunkName: "component---src-pages-recognition-and-rewards-reports-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-rewards-and-recognition-platform-v-1-js": () => import("./../../../src/pages/rewards-and-recognition-platform-v1.js" /* webpackChunkName: "component---src-pages-rewards-and-recognition-platform-v-1-js" */),
  "component---src-pages-rewards-and-recognition-platform-v-2-js": () => import("./../../../src/pages/rewards-and-recognition-platform-v2.js" /* webpackChunkName: "component---src-pages-rewards-and-recognition-platform-v-2-js" */),
  "component---src-pages-rewards-catalog-2-js": () => import("./../../../src/pages/rewards-catalog-2.js" /* webpackChunkName: "component---src-pages-rewards-catalog-2-js" */),
  "component---src-pages-rewards-catalog-23-js": () => import("./../../../src/pages/rewards-catalog-23.js" /* webpackChunkName: "component---src-pages-rewards-catalog-23-js" */),
  "component---src-pages-rewards-js": () => import("./../../../src/pages/rewards.js" /* webpackChunkName: "component---src-pages-rewards-js" */),
  "component---src-pages-rn-r-gcc-js": () => import("./../../../src/pages/RnR-GCC.js" /* webpackChunkName: "component---src-pages-rn-r-gcc-js" */),
  "component---src-pages-second-test-js": () => import("./../../../src/pages/secondTest.js" /* webpackChunkName: "component---src-pages-second-test-js" */),
  "component---src-pages-service-awards-budget-v-1-js": () => import("./../../../src/pages/service-awards-budget-v1.js" /* webpackChunkName: "component---src-pages-service-awards-budget-v-1-js" */),
  "component---src-pages-services-aire-consultation-js": () => import("./../../../src/pages/services/aire-consultation.js" /* webpackChunkName: "component---src-pages-services-aire-consultation-js" */),
  "component---src-pages-services-vantage-gifts-js": () => import("./../../../src/pages/services/vantage-gifts.js" /* webpackChunkName: "component---src-pages-services-vantage-gifts-js" */),
  "component---src-pages-services-vantage-swags-corporate-gifting-js": () => import("./../../../src/pages/services/vantage-swags-corporate-gifting.js" /* webpackChunkName: "component---src-pages-services-vantage-swags-corporate-gifting-js" */),
  "component---src-pages-survey-templates-js": () => import("./../../../src/pages/survey-templates.js" /* webpackChunkName: "component---src-pages-survey-templates-js" */),
  "component---src-pages-sustainability-ghg-js": () => import("./../../../src/pages/sustainability-GHG.js" /* webpackChunkName: "component---src-pages-sustainability-ghg-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-tools-aire-score-assessment-engage-2-excel-js": () => import("./../../../src/pages/tools/aire-score-assessment/engage2excel.js" /* webpackChunkName: "component---src-pages-tools-aire-score-assessment-engage-2-excel-js" */),
  "component---src-pages-tools-aire-score-assessment-engage-2-excel-v-2-js": () => import("./../../../src/pages/tools/aire-score-assessment-engage2excel-v2.js" /* webpackChunkName: "component---src-pages-tools-aire-score-assessment-engage-2-excel-v-2-js" */),
  "component---src-pages-tools-aire-score-assessment-gcc-js": () => import("./../../../src/pages/tools/aire-score-assessment-GCC.js" /* webpackChunkName: "component---src-pages-tools-aire-score-assessment-gcc-js" */),
  "component---src-pages-tools-aire-score-assessment-gcc-shrm-js": () => import("./../../../src/pages/tools/aire-score-assessment-GCC-SHRM.js" /* webpackChunkName: "component---src-pages-tools-aire-score-assessment-gcc-shrm-js" */),
  "component---src-pages-tools-aire-score-assessment-india-js": () => import("./../../../src/pages/tools/aire-score-assessment-India.js" /* webpackChunkName: "component---src-pages-tools-aire-score-assessment-india-js" */),
  "component---src-pages-tools-aire-score-assessment-js": () => import("./../../../src/pages/tools/aire-score-assessment.js" /* webpackChunkName: "component---src-pages-tools-aire-score-assessment-js" */),
  "component---src-pages-tools-aire-score-assessment-pluxee-js": () => import("./../../../src/pages/tools/aire-score-assessment-pluxee.js" /* webpackChunkName: "component---src-pages-tools-aire-score-assessment-pluxee-js" */),
  "component---src-pages-tools-aire-score-assessment-storey-group-js": () => import("./../../../src/pages/tools/aire-score-assessment-storey-group.js" /* webpackChunkName: "component---src-pages-tools-aire-score-assessment-storey-group-js" */),
  "component---src-pages-tools-aire-score-assessment-vc-shrm-24-js": () => import("./../../../src/pages/tools/aire-score-assessment-vc-shrm24.js" /* webpackChunkName: "component---src-pages-tools-aire-score-assessment-vc-shrm-24-js" */),
  "component---src-pages-tools-aire-score-assessment-with-aon-js": () => import("./../../../src/pages/tools/aire-score-assessment-with-aon.js" /* webpackChunkName: "component---src-pages-tools-aire-score-assessment-with-aon-js" */),
  "component---src-pages-tools-aire-score-assessment-with-shrm-js": () => import("./../../../src/pages/tools/aire-score-assessment-with-shrm.js" /* webpackChunkName: "component---src-pages-tools-aire-score-assessment-with-shrm-js" */),
  "component---src-pages-v-1-js": () => import("./../../../src/pages/v1.js" /* webpackChunkName: "component---src-pages-v-1-js" */),
  "component---src-pages-v-2-js": () => import("./../../../src/pages/v2.js" /* webpackChunkName: "component---src-pages-v-2-js" */),
  "component---src-pages-v-3-js": () => import("./../../../src/pages/v3.js" /* webpackChunkName: "component---src-pages-v-3-js" */),
  "component---src-pages-webinars-index-js": () => import("./../../../src/pages/webinars/index.js" /* webpackChunkName: "component---src-pages-webinars-index-js" */),
  "component---src-pages-webinars-vantage-doers-webinar-series-js": () => import("./../../../src/pages/webinars/vantage-doers-webinar-series.js" /* webpackChunkName: "component---src-pages-webinars-vantage-doers-webinar-series-js" */),
  "component---src-pages-webinars-vantage-point-webcast-in-js": () => import("./../../../src/pages/webinars/vantage-point-webcast-in.js" /* webpackChunkName: "component---src-pages-webinars-vantage-point-webcast-in-js" */),
  "component---src-pages-webinars-vantage-point-webcast-usa-js": () => import("./../../../src/pages/webinars/vantage-point-webcast-usa.js" /* webpackChunkName: "component---src-pages-webinars-vantage-point-webcast-usa-js" */),
  "component---src-pages-webinars-vantage-point-webcast-usa-s-2-js": () => import("./../../../src/pages/webinars/vantage-point-webcast-usa-s2.js" /* webpackChunkName: "component---src-pages-webinars-vantage-point-webcast-usa-s-2-js" */),
  "component---src-pages-webinars-vantage-point-webcast-with-dave-ulrich-js": () => import("./../../../src/pages/webinars/vantage-point-webcast-with-dave-ulrich.js" /* webpackChunkName: "component---src-pages-webinars-vantage-point-webcast-with-dave-ulrich-js" */),
  "component---src-pages-webinars-vucap-series-2021-js": () => import("./../../../src/pages/webinars/vucap-series-2021.js" /* webpackChunkName: "component---src-pages-webinars-vucap-series-2021-js" */),
  "component---src-templates-aire-report-template-js": () => import("./../../../src/templates/aire-report-template.js" /* webpackChunkName: "component---src-templates-aire-report-template-js" */),
  "component---src-templates-casestudy-template-js": () => import("./../../../src/templates/casestudy-template.js" /* webpackChunkName: "component---src-templates-casestudy-template-js" */),
  "component---src-templates-competitors-template-js": () => import("./../../../src/templates/competitors-template.js" /* webpackChunkName: "component---src-templates-competitors-template-js" */),
  "component---src-templates-doerswebinars-template-js": () => import("./../../../src/templates/doerswebinars-template.js" /* webpackChunkName: "component---src-templates-doerswebinars-template-js" */),
  "component---src-templates-glossary-template-js": () => import("./../../../src/templates/glossary-template.js" /* webpackChunkName: "component---src-templates-glossary-template-js" */),
  "component---src-templates-industryreport-template-js": () => import("./../../../src/templates/industryreport-template.js" /* webpackChunkName: "component---src-templates-industryreport-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-partneredblogs-template-js": () => import("./../../../src/templates/partneredblogs-template.js" /* webpackChunkName: "component---src-templates-partneredblogs-template-js" */),
  "component---src-templates-productupdate-template-js": () => import("./../../../src/templates/productupdate-template.js" /* webpackChunkName: "component---src-templates-productupdate-template-js" */),
  "component---src-templates-recurso-template-js": () => import("./../../../src/templates/recurso-template.js" /* webpackChunkName: "component---src-templates-recurso-template-js" */),
  "component---src-templates-resource-template-js": () => import("./../../../src/templates/resource-template.js" /* webpackChunkName: "component---src-templates-resource-template-js" */),
  "component---src-templates-survey-template-js": () => import("./../../../src/templates/survey-template.js" /* webpackChunkName: "component---src-templates-survey-template-js" */),
  "component---src-templates-vucap-2021-template-js": () => import("./../../../src/templates/vucap2021-template.js" /* webpackChunkName: "component---src-templates-vucap-2021-template-js" */),
  "component---src-templates-webcastindia-template-js": () => import("./../../../src/templates/webcastindia-template.js" /* webpackChunkName: "component---src-templates-webcastindia-template-js" */),
  "component---src-templates-webcastusa-template-js": () => import("./../../../src/templates/webcastusa-template.js" /* webpackChunkName: "component---src-templates-webcastusa-template-js" */),
  "component---src-templates-webcastusas-2-template-js": () => import("./../../../src/templates/webcastusas2-template.js" /* webpackChunkName: "component---src-templates-webcastusas-2-template-js" */),
  "component---src-templates-webcastwithdave-template-js": () => import("./../../../src/templates/webcastwithdave-template.js" /* webpackChunkName: "component---src-templates-webcastwithdave-template-js" */),
  "component---src-templates-webinar-template-js": () => import("./../../../src/templates/webinar-template.js" /* webpackChunkName: "component---src-templates-webinar-template-js" */),
  "component---src-templates-whitepaper-template-js": () => import("./../../../src/templates/whitepaper-template.js" /* webpackChunkName: "component---src-templates-whitepaper-template-js" */)
}

