// const React = require("react")
// const BacktoTop = require("./src/components/BacktoTop")
const $ = require("jquery")
require("./src/styles/global.css")
const axios = require("axios")
const { func } = require("prop-types")
let currentCountry
const pagesWithNewFooter = require("./static/constants").pagesWithNewFooter
const pagesWithGtmException = require("./static/constants")
  .pagesWithGtmException
const pathToExcludeCookieConsent = ["/request-demo/"]

function injectOptinmonsterScript(u, ac) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://a.omappapi.com/app/js/api.min.js';
  script.async = true;
  script.dataset.user = u;
  script.dataset.account = ac;
  document.body.appendChild(script); // Append script to the body
}

exports.onInitialClientRender = () => {
  //APPENDING OPTINMONSTER SCRIPT BEFORE THE CLOSING BODY TAG
  injectOptinmonsterScript(326622, 345998)
};

exports.onRouteUpdate = () => {
  // document.addEventListener('DOMContentLoaded', () => {
  //   console.log("dom loaded");
    
  //   const anchorLinks = document.querySelectorAll('a[href^="#"]')
  //   // Remove any existing event listeners to avoid duplicates
  //   anchorLinks.forEach(anchor => {
  //     const newAnchor = anchor.cloneNode(true);
  //     anchor.parentNode.replaceChild(newAnchor, anchor);
  //   });

  //   // Attach new event listeners
  //   const newAnchorLinks = document.querySelectorAll('a[href^="#"]');
  //   newAnchorLinks.forEach(anchor => {
  //     anchor.addEventListener('click', (e) => {
  //       e.preventDefault()
  //       console.log("Anchor clicked", anchor);
  //       const targetID = anchor.getAttribute('href').substring(1)
  //       const targetElement = document.getElementById(targetID)

  //       if(targetElement){
  //         window.scrollTo({top: targetElement.offset -140, behavior: 'smooth'})
  //       }
  //     })
  //   })
  // })

  $(document).ready(function () {
    // console.log("Script is running")
    // $('a[href*="#"]').on("click", function (e) {
    //   e.preventDefault()

    //   $("html, body").animate(
    //     {
    //       scrollTop: $($(this).attr("href")).offset().top - 140,
    //     },
    //     500,
    //     "linear"
    //   )
    // })

    /* === Modal === */
    $(".modal-open").on("click", function () {
      $(".popup-overlay, .popup-content").addClass("active")
    })

    $(".modal-close, .popup-overlay").on("click", function () {
      $(".popup-overlay, .popup-content").removeClass("active")
    })

    // THE ABOVE CODE HAS BEEN RE-WRITTEN IN JAVASCRIPT IN exports.onRouteUpdate IN AROUND LINES BETWEEN 230 TO 280 AS OF 22ND may 2024

    $(".modal-open1").on("click", function () {
      $(".popup-overlay1, .popup-content1").addClass("active")
    })

    $(".modal-close1, .popup-overlay1").on("click", function () {
      $(".popup-overlay1, .popup-content1").removeClass("active")
    })
    /* === Modal === */

    /* === Nav tabs starts === */
    $("ul.tabs li").click(function () {
      var tab_id = $(this).attr("data-tab")

      $("ul.tabs li").removeClass("current")
      $(".tab-content").removeClass("current")

      $(this).addClass("current")
      $("#" + tab_id).addClass("current")
    })
    /* === Nav tabs ends === */

    /* === Mobile Menu starts === */
    $(function () {
      $(".acc__title").click(function (j) {
        var dropDown = $(this).closest(".acc__card").find(".acc__panel")
        $(this).closest(".acc").find(".acc__panel").not(dropDown).slideUp()

        if ($(this).hasClass("active")) {
          $(this).removeClass("active")
        } else {
          $(this)
            .closest(".acc")
            .find(".acc__title.active")
            .removeClass("active")
          $(this).addClass("active")
        }

        dropDown.stop(false, true).slideToggle()
        j.preventDefault()
      })
    })
    /* === Mobile Menu ends === */

    $(".cat-button").on("click", function () {
      $(".cat-posts").hide()
      var catId = $(this).attr("catId")
      if ($(this).hasClass("active")) {
        $(this).removeClass("active")
        $("#" + catId).hide()
      } else {
        $(".cat-button").removeClass("active")
        $(this).addClass("active")
        $("#" + catId).show()
      }
    })

    /* === Countdown Timer starts === */
    // Set the date we're counting down to
    var countDownDate = new Date("Nov 9, 2021 13:00:00").getTime()

    // Update the count down every 1 second
    var x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime()

      // Find the distance between now and the count down date
      var distance = countDownDate - now

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24))
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      var seconds = Math.floor((distance % (1000 * 60)) / 1000)

      // Output the result in an element with id="demo"
      const demo = document.getElementById("demo")
      if (demo !== null) {
        document.getElementById("demo").innerHTML =
          `<div>` +
          '<span class = "time">' +
          days +
          "</span>" +
          '<span class = "params">Days</span></div>' +
          "<div>" +
          '<span class = "time">' +
          hours +
          "</span>" +
          '<span class = "params">Hours</span></div>' +
          "<div>" +
          '<span class = "time">' +
          minutes +
          "</span>" +
          '<span class= "params">Mins</span></div>' +
          "<div>" +
          '<span class = "time">' +
          seconds +
          "</span>" +
          '<span class = "params">Secs</span></div>'
      }

      // If the count down is over, write some text
      // let demo = document.getElementById("demo")
      // console.log(demo)

      if (distance < 0 && demo !== null) {
        clearInterval(x)
        // console.log(demo)
        demo.innerHTML = "SERIES OVER"
      }
    }, 1000)
    /* === Countdown Timer ends === */
  })
}

exports.onClientEntry = () => {
  //cookie concent code
  const currPath = getCurrPath()
  function configureCookieConsent() {
    checkIfCurrentPageHaveNewFooterOrGtmException()
    checkCurrCountry().then(country => {
      let allowCookie = true
      let showConsentBar = true
      let storedState = JSON.parse(localStorage.getItem("cookieConsentConfig"))
      if (storedState == null) {
        setInitialLocalStorageStates(country, allowCookie, showConsentBar)
      }
      let gdprSessionStorageState = window.sessionStorage.getItem("allowGtm")

      if (gdprSessionStorageState == "true") {
        setGtm()
      }

      createCustomEventToCheckLocalStorageChanges()
      window.addEventListener(
        "storage",
        () => {
          let gdprSessionStorageState = window.sessionStorage.getItem(
            "allowGtm"
          )
          if (gdprSessionStorageState == true) {
            setGtm()
          } else {
            return
          }
        },
        false
      )
    })
  }

  configureCookieConsent()

  //add gtm without consent
  function addGtmWithoutConsent() {
    if (pathToExcludeCookieConsent.includes(currPath)) {
      setGtm()
    }
  }
  addGtmWithoutConsent()

  //revenue hero code
  addRevenueHeroScriptPromise
    .then(executeRevenueHeroScript())
    .catch(error => console.log(error))
}

const urlSet = new Set([
  "/",
  "https://www.vantagecircle.com/",
  "/rewards2",
  "/home-2023",
  "/amazon-business-integration/",
  "/products/rewards-recognition/",
  "/products/employee-survey/",
  "/products/employee-discount/",
  "/integration/microsoft-teams/",
  "/rewards-and-recognition/",
  "/vc-perks/",
  "/R&R-GCC/"
])

// THINGS TO PUT IN THE HTML FOR POPUP:
// id = "watchVideoClass" in the parent container (section)
// class='youtube-video-btn' in watch video button,
// popup-overlay class in the modal container
// modal-close class in close button,
// keep src attribute in iframe empty,
// create data-src attribute and put the url there
// comment the src doc (IF IT IS THERE)

exports.onRouteUpdate = ({ location, prevLocation }) => {
// exports.onInitialClientRender = () => {
  // if (!checkUrl()) return
  // youtubeUrls = youtubeUrls.map(obj => {
  //   return {
  //     ...obj,
  //     buttonOpen: document.getElementById(obj.btnId),
  //     buttonClose: document.getElementsByClassName(obj.closeBtnClass)[0],
  //     overlayButton: document.getElementsByClassName(obj.overlayClass)[0],
  //   }
  // })

  youtubeUrls = {
    ...youtubeUrls,
    buttonOpen: document.getElementById("watchVideoClass"),
    buttons: [...document.getElementsByClassName("youtube-video-btn")],
    buttonClose: document.getElementsByClassName("modal-close")[0],
    overlayButton: document.getElementsByClassName("popup-overlay")[0],
  }
  const modalOpen = document.getElementsByClassName("modal-open")
  const popupOverlay = document.getElementsByClassName("popup-overlay")
  const popupContent = document.getElementsByClassName("popup-content")

  // console.log('modal',popupOverlay[0]);
  modalOpen[0]?.addEventListener('click', () => {
    popupOverlay[0].classList.add("active")
    popupContent[0].classList.add("active")
  })
  handleYoutubeUrls(youtubeUrls)
}

/* GTM CODES STARTS */

//default id for english site
let setID = `KDT5ZH8`

function getCurrPath() {
  let currPath = window.location.pathname
  let firstPath = "/" + currPath.split("/")[1] + "/"
  return firstPath
}

//add gtm for pages with new footer
function checkIfCurrentPageHaveNewFooterOrGtmException() {
  let firstPath = getCurrPath()
  pagesWithNewFooter.forEach(e => {
    if (e === firstPath) {
      setGtm()
    }
  })
  pagesWithGtmException.forEach(e => {
    if (e === firstPath) {
      setGtm()
    }
  })
}

const setGtm = () => {
  insertJS()
}

const addgtmLink = `https://www.googletagmanager.com/gtm.js?id=GTM-${setID}`

//add gtm
const insertJS = () => {
  const addIframe = src => {
    const s = document.createElement(`noscript`)
    const iframe = document.createElement(`iframe`)
    iframe.src = src
    iframe.height = "0"
    iframe.style = "visiblity:hidden"
    document.getElementsByTagName(`body`)[0].appendChild(s)
      .append(`<iframe  src="https://www.googletagmanager.com/ns.html?id=GTM-${setID}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`)
  }
  const insertF = () => {
    const s = document.createElement(`script`)
    const src = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-${setID}');`
    document.getElementsByTagName(`head`)[0].appendChild(s).append(src)
  }
  addIframe(addgtmLink)
  insertF()
  
}
const insertBamboo = () => {
  const s = document.createElement(`script`)
  const src = `(function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('kn9Eq4RlG6b8RlvP');`
  document.getElementsByTagName(`head`)[0].appendChild(s).append(src)
}
insertBamboo()

const checkCurrCountry = async () => {
  try {
    let requestUrl = "https://geolocation-db.com/json/"
    let res = await axios.get(requestUrl)
    currentCountry = await res.data.country_name
    return currentCountry
  } catch (error) {
    console.log(error)
  }
}

const createCustomEventToCheckLocalStorageChanges = () => {
  var evt = document.createEvent("StorageEvent")
  evt.initStorageEvent(
    "storage",
    false,
    false,
    "allowGtm",
    "oldValue",
    "newValue",
    null,
    window.localStorage
  )
  window.dispatchEvent(evt)
}

const setInitialLocalStorageStates = (...args) => {
  const [country, allowCookie, showConsentBar] = args
  let initialObj = JSON.stringify({
    showConsentBar: showConsentBar,
    allowCookie: allowCookie,
    country: country,
  })
  localStorage.setItem("cookieConsentConfig", initialObj)
  sessionStorage.setItem("allowGtm", "true")
}

/* WEBMASTER SCHEMA STARTS */

const urls = [
  "https://www.vantagecircle.com/",
  "https://www.vantagecircle.com/about-us/",
  "https://www.vantagecircle.com/contact-us/",
]

const ldjson = [
  `
    {"@context": "http://schema.org",
    "@graph": [
        {
    "@type": "WebSite",
    "name": "Vantage Circle",
    "url": "https://www.vantagecircle.com",
    "inLanguage": "en-US"
    },
    {
    "@type": "WebSite",
    "name": "Vantage Circle Spanish Site",
    "url": "https://www.vantagecircle.com/es/",
    "inLanguage": "es-ES"
    },
    {
    "@type": "WebSite",
    "name": "Vantage Circle French Site",
    "url": "https://www.vantagecircle.com/fr/",
    "inLanguage": "fr-FR"
    },
    {
    "@type": "WebSite",
    "name": "Vantage Circle German Site",
    "url": "https://www.vantagecircle.com/de/",
    "inLanguage": "de-DE"
    },
    {
    "@type": "WebSite",
    "name": "Vantage Circle Dutch Site",
    "url": "https://www.vantagecircle.com/nl/",
    "inLanguage": "nl-NL"
    },
    {
    "@id": "https://www.vantagecircle.com/#organization",
    "@type": "Organization",
     "name": "Vantage Circle",
     "description": "Vantage Circle is a Global Employee Engagement Platform to create great workplaces using solutions for Employee Rewards & Recognition, Corporate Wellness, Employee Feedback, and Corporate Discounts catering to 400+ companies globally.",
     "url": "https://www.vantagecircle.com",
     "foundingDate": "2011",
     "logo": {
     "@type": "ImageObject",
     "url": "https://res.cloudinary.com/vantagecircle/image/upload/vantagecms/uploads/2020/08/vantage-circle-logo-compressed.png",
    "width": 300,
    "height": 44
    },
    "address":
             [
               {
                 "@type": "PostalAddress",
                 "streetAddress": "C1-1151/Vasant Kunj",
                 "addressLocality": "New Delhi",
                 "postalCode": "110070",
                 "addressCountry": "IN"
                },
                {
                 "@type": "PostalAddress",
                 "streetAddress": "4512 Legacy Drive, Suite100, Plano",
                 "addressLocality": "Texas",
                    "postalCode": "75024",
                    "addressCountry": "US"
                  },
                  {
                    "@type": "PostalAddress",
                    "streetAddress": "Suite 1A, Level 2, 802-808 Pacific Highway, Gordon,",
                    "addressLocality": "New South Wales",
                    "postalCode": "2072",
                    "addressCountry": "AU"
                  },
                  {
                    "@type": "PostalAddress",
                    "streetAddress": "Suite 1224, 211 Aspen Stone Blvd,",
                    "addressLocality": "Calgary",
                    "postalCode": "T3H0K1",
                    "addressCountry": "CA"
                  },
                  {
                    "@type": "PostalAddress",
                    "streetAddress": "Startbaan 10 G, Amstelveen,",
                    "addressLocality": "Netherlands",
                    "postalCode": "1185 XR",
                    "addressCountry": "NL"
                  }
                ],
          "contactPoint" : [
            { 
              "@type": "ContactPoint",
              "contactType": "customer support",
              "areaServed" : ["AF","NA","OC","AS","EU","SA"],
              "availableLanguage" : ["English"],
              "email": "support@vantagecircle.com"
            }
          ],
          "sameAs": [
            "https://twitter.com/VantageCircle/",
            "https://www.linkedin.com/company/vantage-circle/",
            "https://www.facebook.com/vantagecircle/",
            "https://www.instagram.com/vantagecircle/",
            "https://www.youtube.com/channel/UCzb6J4NEmhuGpj_-u2kz7aw",
            "https://www.pinterest.com/vantagecircle/"
          ]
          
        }
      ]
    }`,
  ` { 
      "@context":"http://schema.org",
      "@type":"AboutPage",
      "mentions":["https://www.vantagecircle.com/about-us/"],
      "name":"About Vantage Circle",
      "url":"https://www.vantagecircle.com/about-us/",
      "about":
        { 
        "@type":"Organization",
        "url":"https://www.vantagecircle.com",
        "name":"Vantage Circle",
        "description":"We believe in empowering today’s workplaces by offering them rewards and recognition, employee benefits, wellness, and feedback solutions. Our goal is to forge meaningful connections between employees and their organizations. We keep a global perspective to ensure that your employee engagement goals are fulfilled.",
        "logo":"https://res.cloudinary.com/vantagecircle/image/upload/vantagecms/uploads/2020/08/vantage-circle-logo-compressed.png",
        "sameAs":
          [   
            "https://twitter.com/VantageCircle/","https://www.facebook.com/vantagecircle/",
            "https://www.linkedin.com/company/vantage-circle/",
            "https://www.youtube.com/channel/UCzb6J4NEmhuGpj_-u2kz7aw/",
            "https://www.pinterest.com/vantagecircle/",
            "https://www.instagram.com/vantagecircle/"
          ],
        "disambiguatingDescription":"We believe in building an excellence-driven culture with creative and innovative people from diverse backgrounds. To deliver and understand the needs of our diverse customers, we must practice inclusivity.",
        "foundingLocation":"https://www.wikidata.org/wiki/Q987",
        "parentOrganization":"Bargain Technologies Private Limited",
        "areaServed":
        [
          "https://www.wikidata.org/wiki/Q48",
          "https://www.wikidata.org/wiki/Q46",
          "https://www.wikidata.org/wiki/Q15",
          "https://www.wikidata.org/wiki/Q55643",
          "https://www.wikidata.org/wiki/Q18",
          "https://www.wikidata.org/wiki/Q49"
        ],
        "knowsAbout":
          [
            "https://www.wikidata.org/wiki/Q4113880",
            "https://www.wikidata.org/wiki/Q96377259",
            "https://www.wikidata.org/wiki/Q5374302",
            "https://www.wikidata.org/wiki/Q37904560"
          ],
        "knowsLanguage":["https://www.wikidata.org/wiki/Q1860","https://www.wikidata.org/wiki/Q150", "https://www.wikidata.org/wiki/Q1321" ],
        "@id":"https://www.vantagecircle.com/"},
        "@id":"https://www.vantagecircle.com/about-us/"
    }`,
  `  {"@context":"http://schema.org",
    "@type":"ContactPage",
    "description":"Get in touch with Vantage Circle team to resolve all your queries related to Employee Rewards & Recognition, Employee Discounts, Employee Feedback and Corporate Wellness.",
   "name":"Contact Page",
   "url":"https://www.vantagecircle.com/contact-us/",
   "Image":
        {"@type":"ImageObject",
                    "url":"https://res.cloudinary.com/vantagecircle/image/upload/vantagecms/uploads/2020/08/vantage-circle-logo-compressed.png",
          "@id":"https://res.cloudinary.com/vantagecircle/image/upload/vantagecms/uploads/2020/08/vantage-circle-logo-compressed.png"},
          "publisher":{"@type":"Organization",
          "url":"https://www.vantagecircle.com/",
          "name":"Vantage Circle",
          "description":"Vantage Circle is a Global Employee Engagement Platform to create great workplaces using solutions for Employee Rewards & Recognition, Corporate Wellness, Employee Feedback, and Corporate Discounts.",
          "logo":{"@id":"https://res.cloudinary.com/vantagecircle/image/upload/vantagecms/uploads/2020/08/vantage-circle-logo-compressed.png"},
          "sameAs":["https://www.facebook.com/vantagecircle/","https://twitter.com/VantageCircle/","https://www.linkedin.com/company/vantage-circle/","https://www.youtube.com/channel/UCzb6J4NEmhuGpj_-u2kz7aw/","https://www.pinterest.com/vantagecircle/","https://www.instagram.com/vantagecircle/"],
          "foundingLocation":"https://www.wikidata.org/wiki/Q987",
          "parentOrganization":"Bargain Technologies Private Limited",
          "areaServed":"https://www.wikidata.org/wiki/Q2",
          "knowsAbout":["https://www.wikidata.org/wiki/Q4113880","https://www.wikidata.org/wiki/Q96377259","https://www.wikidata.org/wiki/Q5374302","https://www.wikidata.org/wiki/Q37904560"],
          "knowsLanguage":["https://www.wikidata.org/wiki/Q1860","https://www.wikidata.org/wiki/Q150","https://www.wikidata.org/wiki/Q1321"],
          "@id":"https://www.vantagecircle.com/"},
          "@id":"https://www.vantagecircle.com/contact-us/"}`,
]

for (let i = 0; i <= urls.length; i++) {
  if (document.URL == urls[i]) {
    const s = document.createElement(`script`)
    s.type = "application/ld+json"
    const json = ldjson[i]
    document.getElementsByTagName(`head`)[0].appendChild(s).append(json)
  }
}

// FOR BREADCRUMB SCHEMA

const breadcrumbsSchema = [
  {
    url: "/products/rewards-recognition/",
    schema: `{"@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home",
      "item": "https://www.vantagecircle.com/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Solutions",
      "item": "https://www.vantagecircle.com/employee-engagement-software/"  
    },{
      "@type": "ListItem", 
      "position": 3, 
      "name": "Vantage Rewards",
      "item": "https://www.vantagecircle.com/products/rewards-recognition/"  
    }]}`,
  },

  {
    url: "/products/employee-survey/",
    schema: `{"@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home",
      "item": "https://www.vantagecircle.com/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Solutions",
      "item": "https://www.vantagecircle.com/employee-engagement-software/"  
    },{
      "@type": "ListItem", 
      "position": 3, 
      "name": "Vantage Pulse",
      "item": "https://www.vantagecircle.com/products/employee-survey/"  
    }]}`,
  },

  {
    url: "/products/employee-discount/",
    schema: `{"@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home",
      "item": "https://www.vantagecircle.com/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Solutions",
      "item": "https://www.vantagecircle.com/employee-engagement-software/"  
    },{
      "@type": "ListItem", 
      "position": 3, 
      "name": "Vantage Perks",
      "item": "https://www.vantagecircle.com/products/employee-discount/"  
    }]}`,
  },
]

for (let i = 0; i < breadcrumbsSchema.length; i++) {
  let pageUrl = breadcrumbsSchema[i].url
  let schema = breadcrumbsSchema[i].schema
  if (window.location.pathname == pageUrl) {
    const s = document.createElement(`script`)
    s.type = "application/ld+json"
    const json = schema
    document.getElementsByTagName("head")[0].appendChild(s).append(json)
  }
}

//INSERT GROWSURF

const growSurfUrls = ["https://www.vantagecircle.com/thank-you-page-affiliate/"]

const growSurfScript = [
  `(function(g,r,s,f){g.grsfSettings={campaignId:"xl7rpm",version:"2.0.0"};s=r.getElementsByTagName("head")[0];f=r.createElement("script");f.async=1;f.src="https://app.growsurf.com/growsurf.js"+"?v="+g.grsfSettings.version;f.setAttribute("grsf-campaign", g.grsfSettings.campaignId);!g.grsfInit?s.appendChild(f):"";})(window,document);`,
]

for (let i = 0; i <= growSurfUrls.length; i++) {
  if (document.URL.includes(growSurfUrls[i])) {
    const s = document.createElement(`script`)
    s.type = "text/javascript"
    const src = growSurfScript[0]
    document.getElementsByTagName(`head`)[0].appendChild(s).append(src)
  }
}

//insert youtube urls

let youtubeUrls = [
  {
    // btnId: "youtube-video-btn",
    // closeBtnClass: "modal-close",
    // overlayClass: "popup-overlay",
  },
]

const checkUrl = () => {
  let currPage = window.location.pathname
  let flag
  if (urlSet.has(currPage)) flag = true
  else flag = false
  return flag
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[[\]]/g, "\\$&")
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ""
  return decodeURIComponent(results[2].replace(/\+/g, " "))
}
// Change the hero text based on the URL parameter
window.onload = function () {
  var parameterValue = getParameterByName("keyword")
  if (parameterValue) {
    var heroText = document.querySelectorAll(".hero-text")
    heroText.forEach(text => {
      if (text !== null) {
        text.textContent = parameterValue
      }
    })
  }
}

const handleYoutubeUrls = e => {
  if (!e["buttonOpen"]) return
  let modalChild = document.getElementsByClassName("modal-content")[0]
  let iframe = modalChild?.getElementsByTagName("iframe")[0]
  let iframeDataSrc = iframe?.getAttribute("data-src")
  let iframeSrc = iframe?.getAttribute("src")
  // console.log("rfg",e.target.matches(e["buttons"]))

  e["buttonOpen"].addEventListener("click", ev => {
    if (e["buttons"].includes(ev.target)) {
      if (!iframeSrc) {
        modalChild.getElementsByTagName("iframe")[0].src = iframeDataSrc
      }
    }
  })

  if (!e["buttonClose"]) return
  const handleCloseBtn = () => {
    let iframeSrc = iframe.getAttribute("src")
    if (iframeSrc) {
      modalChild
        .getElementsByTagName("iframe")[0]
        .setAttribute("data-src", iframeSrc)
      modalChild.getElementsByTagName("iframe")[0].removeAttribute("src")
    }

  const popupOverlay = document.getElementsByClassName("popup-overlay")
  const popupContent = document.getElementsByClassName("popup-content")
  
    popupOverlay[0].classList.remove("active")
    popupContent[0].classList.remove("active")

  }

  e["buttonClose"].addEventListener("click", () => {
    handleCloseBtn()
  })
  e["overlayButton"].addEventListener("click", () => {
    handleCloseBtn()
  })
}

// exports.wrapRootElement = (element) => {
//   return <BacktoTop>{element}</BacktoTop>
// }
//add  revenueHero script
let addRevenueHeroScriptPromise = new Promise(function (resolve, reject) {
  // Create a new script element and set its attributes
  var script = document.createElement("script")
  script.type = "text/javascript"
  script.src = "https://app.revenuehero.io/scheduler.min.js"
  let loaded = false
  // Append the script element to the head element of the document
  var head = document.getElementsByTagName("head")[0]
  head.appendChild(script)
  script.onload = loaded = true
  if (script.onload) {
    resolve()
  } else reject("rejected")
})

//revenue hero code
function executeRevenueHeroScript() {
  var targetFormIds = [
    `820eb10c-64cb-4c78-a574-1077de274aed`,
    `50775a1c-3680-401b-b6ff-cc6e54043ed8`,
    `4da06141-2053-49bc-8f72-ea5ab8bb585e`,
    `d2e2c7bf-c255-4b72-a236-53240ebeed17`,
    "d1fc98c2-2412-4dc3-97ec-56829decff7f",
    "2d650af8-52e0-4941-a2cc-b3d8ee12d714",
    "d20b7409-6dcc-4bdc-ad67-7321eb5b4ddb",
    "65d41591-6d6e-4fe7-b2aa-59612c13a95",
    "65d41591-6d6e-4fe7-b2aa-59612c13a956",
    "447cb224-32ec-4515-b5a2-21e58f938ed9",
    "b868f797-e154-4581-8660-42c12273da37",
    "32db6659-8e32-41ec-8e76-ed092f549062",
    "e8d9dbdc-b7b0-44da-a06b-cbfafceb5674",
    "65783843-70fa-47df-9cd7-a5ad23b1ac19"
  ]
  if (typeof window === "undefined") return
  if (window.addEventListener) {
    window.addEventListener("message", handleFormSubmitEvent, false)
  } else if (window.attachEvent) {
    window.attachEvent("onmessage", handleFormSubmitEvent)
  }
  function handleFormSubmitEvent(event) {
    if (
      event.data.type === "hsFormCallback" &&
      event.data.eventName === "onFormSubmitted"
    ) {
      var submittedData = event.data.data.submissionValues
      var formId = event.data.id
      // Check if the submitted form's ID is in the targetFormIds array
      if (targetFormIds.includes(formId)) {
        var formValues = event.data.data.submissionValues
        //replace routerID with the router ID you've setup
        const RevenueHero = window.RevenueHero
        const hero = new RevenueHero({ routerId: `132` })
        hero.submit(formValues).then(sessionData => {
          hero.dialog.open(sessionData)
        })
      }
    }
  }
}
