module.exports.languagesArr = ["en", "fr", "es", "de"]

module.exports.languageandFlags = {
  en:
    "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/06/united-states.png",
  fr:
    "https://res.cloudinary.com/vantagecircle/image/upload/v1684754223/gatsbycms/uploads/2023/05/france.png",
  es:
    "https://res.cloudinary.com/vantagecircle/image/upload/v1684754223/gatsbycms/uploads/2023/05/spain.png",

  de:
    "https://res.cloudinary.com/vantagecircle/image/upload/v1684754223/gatsbycms/uploads/2023/05/germany.png",

  // nl:
  //   "https://res.cloudinary.com/vantagecircle/image/upload/v1684754224/gatsbycms/uploads/2023/05/netherlands.png",

  // pt:
  //   "https://res.cloudinary.com/vantagecircle/image/upload/v1684754223/gatsbycms/uploads/2023/05/portugal.png",
}

module.exports.gdprCountries = [
  "Austria",
  "Belgium",
  "Bulgaria",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Ireland",
  "Italy",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Netherlands",
  "Poland",
  "Portugal",
  "Romania",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "United Kingdom",
]

module.exports.pagesWithNewFooter = [
  `/webinar-series-registration/`,
  `/employee-recognition-v1/`,
  `/employee-recognition-program-v1/`,
  `/employee-rewards-platform-v1/`,
  `/employee-recognition-platform-v1/`,
  `/employee-recognition-v2/`,
  "/employee-rewards-recognition-program-v1/",
  "/employee-rewards-recognition-platform-v1/",
  "/employee-rewards-recognition-program-v2/",
  "/employee-rewards-recognition-platform-v2/",
  "/employee-rewards-recognition-program-v3/",
  "/employee-rewards-recognition-platform-v3/",
  "/rewards-and-recognition-buyers-guide-v1/",
  "/paid-landing-page/",
  "/paid-landing-page",
  "/amazon-paid-page",
  "/amazon-paid-page/",
  "/employee-recognition-program-v2/",
  "/employee-recognition-program-v2",
  "/employee-recognition-program-v3/",
  "/employee-recognition-program-v3",
  "/employee-recognition-program-v4/",
  "/employee-recognition-program-v4",
  "/v1",
  "/v1/",
  "/v2",
  "/v2/",
  "/v3",
  "/v3/",
  "/book-a-demo-capterra",
  "/book-a-demo-capterra/",
  `/g2`,
  `/g2/`,
  `/book-a-demo-sourceforge`,
  `/book-a-demo-sourceforge/`,
  `/book-a-demo-ssr`,
  `/book-a-demo-ssr/`,
  `/employee-engagement-survey-platform-v1`,
  `/employee-engagement-survey-platform-v1/`,
  `/RnR-GCC`,
  `/RnR-GCC/`,
  `/book-a-meeting-v1`,
  `/book-a-meeting-v1/`,
  `/rewards-and-recognition-platform-v2`,
  `/rewards-and-recognition-platform-v2/`,
  `/rewards-and-recognition-platform-v1`,
  `/rewards-and-recognition-platform-v1/`
]

module.exports.pageswithAIREfooter = [
  "/tools/aire-score-assessment/engage2excel",
  "/tools/aire-score-assessment/engage2excel/",
]

module.exports.pageswithNOfooter = [
  "/privacy-policy-vc/",
  "/privacy-policy-vc",
  "/terms-and-conditions-vc/",
  "/terms-and-conditions-vc",
  "/aire-score-assessment/engage2excel",
  "/aire-score-assessment/engage2excel/",
  "/request-demo/",
  "/request-demo",
  "/request-a-demo",
  "/request-a-demo/"
]

module.exports.pagesWithGtmException = [
  "/thank-you-page-ads-program/",
  "/thank-you-page-ads-platform/",
]
